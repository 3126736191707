<template>
  <g>
    <g
      id="g15"
      v-for="(block, index) in points"
      ref="connectorblock"
      :key="index"
    >
      <image
        :x="block.schematic_x * svgScale"
        :y="block.schematic_y * svgScale"
        :data-index="index"
        :data-pk="block.pk"
        :index="index"
        :href="block.annotated_svg_file"
        :height="block.height * block.connector.ui_scale"
        :width="block.width"
        @dblclick="blockClickHandler(block)"
        :class="`dragcb${block.pk}`"
      />
    </g>
  </g>
</template>
<script>
import interact from "interactjs";
export default {
  name: "TestPointView",
  props: {
    connectorBlocks: Array,
    renderScale: Number,
    svgScale: Number,
  },
  data() {
    return {
      points: JSON.parse(JSON.stringify(this.connectorBlocks)),
    };
  },
  methods: {
    activateDragAndDrop(outsideCall) {
      setTimeout(() => {
        if (outsideCall) {
          this.points = JSON.parse(JSON.stringify(this.connectorBlocks));
        }
        this.points.forEach((o, index) => {
          if (outsideCall) {
            interact(`.dragcb${o.pk}`).unset();
          }
          interact(`.dragcb${o.pk}`).draggable({
            onmove: (event) => {
              this.points[index].schematic_x +=
                (event.dx / this.svgScale) * this.renderScale;
              this.points[index].schematic_y +=
                (event.dy / this.svgScale) * this.renderScale;

              event.target.style.transform = `translate(${this.points[index].schematic_x}px, ${this.points[index].schematic_y}px)`;
            },
            onend: () => {
              let index2 = index;
              this.$store.dispatch("tpcb/patchConnectorBlock", {
                pk: this.points[index2].pk,
                data: {
                  schematic_x:
                    Math.round(this.points[index2].schematic_x * 100) / 100,
                  schematic_y:
                    Math.round(this.points[index2].schematic_y * 100) / 100,
                },
              });
            },
          });
        });
      }, 100);
    },
    updateConnectorList(block) {
      const record = this.points.find((element) => element.pk == block.pk);
      if (record) {
        Object.assign(record, block);
      }
    },
    removeConnectorBlock(pk) {
      const record = this.points.find((element) => element.pk == pk);
      if (record) {
        this.points.splice(this.points.indexOf(record), 1);
      }
    },
    blockClickHandler(block) {
      this.$emit("blockClicked", block);
    },
  },
  mounted() {
    this.activateDragAndDrop(false);
  },
};
</script>
